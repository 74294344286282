var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-queue-health" },
    [
      _c("h1", [_vm._v("Production Queue Health")]),
      _vm.most_recent_queue_health
        ? _c(
            "b-table-simple",
            [
              _c(
                "b-tbody",
                [
                  _c(
                    "b-tr",
                    [
                      _c("b-th", [_vm._v("Number of Messages:")]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm.most_recent_queue_health["data"][
                              "ApproximateNumberOfMessages"
                            ]
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-tr",
                    [
                      _c("b-th", [_vm._v("Messages in Flight:")]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm.most_recent_queue_health["data"][
                              "ApproximateNumberOfMessagesNotVisible"
                            ]
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-tr",
                    [
                      _c("b-th", [_vm._v("Last time you checked the queue:")]),
                      _c("b-td", [
                        _vm._v(_vm._s(_vm.formatTimestamp(_vm.last_updated)))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" " + _vm._s(_vm.most_recent_queue_health) + " "),
      _c("p", [
        _vm._v(
          "All values are approximate! AWS doesn't guarantee the timeliness of values, but they are usually pretty close."
        )
      ]),
      _c(
        "b-button",
        {
          on: {
            click: function($event) {
              return _vm.updateHealthStatus()
            }
          }
        },
        [_vm._v("Update")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }