<template>
  <div class="admin-queue-health">
    <h1>Production Queue Health</h1>
    <b-table-simple v-if="most_recent_queue_health">
      <b-tbody>
        <b-tr>
          <b-th>Number of Messages:</b-th>
          <b-td>{{most_recent_queue_health['data']['ApproximateNumberOfMessages']}}</b-td>
        </b-tr>
        <b-tr>
          <b-th>Messages in Flight:</b-th>
          <b-td>{{most_recent_queue_health['data']['ApproximateNumberOfMessagesNotVisible']}}</b-td>
        </b-tr>
        <b-tr>
          <b-th>Last time you checked the queue:</b-th>
          <b-td>{{formatTimestamp(last_updated) }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    {{ most_recent_queue_health }}
    <p>All values are approximate! AWS doesn't guarantee the timeliness of values, but they are usually pretty close.</p>
    <b-button @click="updateHealthStatus()">Update</b-button>
  </div>
</template>

<script>
import moment from 'moment'
import * as DataProvider from '../../components/helpers/DataProvider'

export default {
  name: 'admin-queue-health',
  components: {},
  data: function () {
    return {
      most_recent_queue_health: null,
      last_updated: null
    }
  },
  mounted () {
    this.updateHealthStatus()
  },
  methods: {
    async updateHealthStatus () {
      this.most_recent_queue_health = await DataProvider.adminGetQueueHealth(
        'ProductionQueue'
      )
      console.log('Got queue health:')
      console.log(this.most_recent_queue_health)
      this.last_updated = new Date()
    },
    formatTimestamp (timestamp) {
      return moment(timestamp).format('HH:mm')
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../variables';
.admin-queue-health {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2%;
  overflow-y: auto;
}
</style>
